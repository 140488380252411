import GreenskyLandingImage from "../../../assets/Greensky/GreenskyLandingImage.png";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import useLogEvent from "../../../hooks/useLogEvent";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";
import { useTranslation } from "react-i18next";

const GreenskyLanding = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs, token, routerVersion, language } = user;
    const { logEvent } = useLogEvent();
    const { t } = useTranslation();

    const clickStart = () => {
        // logEvent("User clicked start data collection");
        logEvent(CustJourneyCodes.landing.clickStartButton.status);
        navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    return (
        <div className='d-flex justify-content-center align-items-center flex-column text-center'>
            <h1 className='mt-5 text-primary'>{t("greensky.landingText1")}</h1>
            <p className='mt-4 mb-5'>{t("greensky.landingText2")}</p>
            <img className='col-12 col-sm-10 col-md-8 col-lg-3 mb-5' src={GreenskyLandingImage} />
            <Button className='col-11 col-md-4 p-2' variant='primary' onClick={clickStart}>
                {t("greensky.landingButtonText")}
            </Button>
        </div>
    );
};

export default GreenskyLanding;
