/**
 * The `YahooEUCompletePage` component represents a page indicating the completion of a task or process for YahooEU.
 *
 * It typically displays a success message or relevant content and may include a button or link to navigate
 * back to the main or home page. This component uses React Bootstrap components for styling.
 *
 * @component
 * @example
 * // Import the YahooEUCompletePage component in your application
 * import YahooEUCompletePage from './YahooEUCompletePage';
 *
 * // Render the YahooEUCompletePage component within a Route
 * <Route path='/complete' element={<YahooEUCompletePage />} />
 *
 * // When navigating to '/complete', the YahooEUCompletePage component will be displayed.
 *
 * @returns {JSX.Element} The JSX element representing the completion page.
 */
import { Stack } from "react-bootstrap";
import YahooEUCheckMark from "../../../assets/YahooEU/YahooEUCheckMark";
import { usePostStatusUpdateMutation } from "../../../api/api";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const YahooEUCompletePage = () => {
    const { t } = useTranslation();
    const [postStatusUpdate, { isSuccess: isPostStatusSuccess }] = usePostStatusUpdateMutation();
    const location = useLocation();
    const isSubmitFrontSuccess = location?.state?.isSubmitFrontSuccess;

    useEffect(() => {
        // console message index = 24
        if (isSubmitFrontSuccess) {
            try {
                const yahooCompleteUpdate = {
                    statusModuleName: "IDN-UI-ProcessingScreen",
                    statusMessage: "Success: Request Accepted for Processing",
                    satusError: null,
                    statusCode: 3,
                };
                /** Post YahooEU status update */
                postStatusUpdate(yahooCompleteUpdate);
            } catch (error) {
                // previously a console statement
                // console message index = 23
            }
        }
    }, [isSubmitFrontSuccess]);

    return (
        <>
            <Stack gap={5} className='align-items-center text-center'>
                <h1 className='fw-bolder mb-5'>{t("yahooLanding.complete")}</h1>
                <YahooEUCheckMark />
                <p className='fst-italic fw-bold col-9 col-md-5 col-lg-4 col-xl-3 fw-bold fs-5 mt-5'>{t("yahooLanding.completeMessage")}</p>
            </Stack>
        </>
    );
};

export default YahooEUCompletePage;
