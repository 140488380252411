import { useTranslation, Trans } from "react-i18next";

const FirstAmericanTitleComplete = () => {
    const { t } = useTranslation();

    return (
        <div className='container h-75 d-flex flex-column justify-content-center align-items-center'>
            <div className='d-flex flex-column text-center'>
                <h1 className='text-success fw-bold'>{t("firstamericantitle.completeText1")}</h1>
                <h3 className='fw-bold'>{t("firstamericantitle.completeText2")}</h3>
            </div>
            <p className='col-10 text-center lh-sm text-dark mt-5'>
                <Trans i18nKey='firstamericantitle.completeText3'>
                    In a few moments, this <span className='fw-bold'>page will automatically refresh</span>, and you can continue with next steps.
                </Trans>
            </p>
            <p className='mt-5'>
                <b>{t("firstamericantitle.completeText4")}</b>
            </p>
        </div>
    );
};

export default FirstAmericanTitleComplete;
