import { Button, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BackgroundAndLogo from "../../../components/BackgroundAndLogo/BackgroundAndLogo";

const AcmeCompletePage = () => {
    const navigate = useNavigate();
    const rootRouteHandler = () => {
        navigate(`/`);
        window.location.reload();
    };
    const isSimData = process.env.REACT_APP_SIMULATED;

    return (
        <BackgroundAndLogo>
            <Stack gap={5} className='d-flex text-center mt-5 justify-content-start align-items-center'>
                {isSimData ? (
                    <>
                        <h1 className='text-success fw-bold'>Submitted</h1>
                        <h3 className='mt-3'>Your identity validation is complete. Thanks for using Intellicheck® Capture.</h3>
                        <h3 className='fw-bold mt-3'>You can close this page.</h3>
                        <Button variant='success' className='w-50' onClick={() => rootRouteHandler()}>
                            New Simulation
                        </Button>
                        <p className='position-sticky bottom-0 mb-1'>Simulator does not collect, store, or submit data.</p>
                    </>
                ) : (
                    <>
                        <h1 className='text-success fw-bold'>Congratulations!</h1>
                        <h3 className='fw-bold'>
                            Your ID validation has been submitted. You will be notified once your application has been approved!
                        </h3>
                        <Button variant='success' className='w-25' onClick={() => rootRouteHandler()}>
                            End
                        </Button>
                    </>
                )}
            </Stack>
        </BackgroundAndLogo>
    );
};

export default AcmeCompletePage;
