import { useEffect, useRef, MutableRefObject, useState } from "react";
import { EnumCapturedResultItemType, type DSImageData, type OriginalImageResultItem } from "dynamsoft-core";
import { type NormalizedImageResultItem } from "dynamsoft-document-normalizer";
import { CameraEnhancer, CameraView, ImageEditorView, type DCEFrame, DrawingStyleManager } from "dynamsoft-camera-enhancer";
import { CapturedResultReceiver, CaptureVisionRouter, type SimplifiedCaptureVisionSettings } from "dynamsoft-capture-vision-router";
import { useIngestBackImageMutation, useIngestFrontImageMutation, usePostStatusUpdateAzureMutation } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { RootState } from "../../../redux/store";
import { getNextPage } from "../../../redux/slices/user.slice";
import DrawingLayer from "dynamsoft-camera-enhancer/dist/types/class/drawinglayer";
import CaptureButtons from "../../CaptureButtons/CaptureButtons";
import CaptureMessages from "../../CaptureMessages/CaptureMessages";
import frontOverlay from "../../../assets/dlFront.png";
import sharpenImage from "../../../utils/sharpenImage";
import useLogEvent from "../../../hooks/useLogEvent";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";

declare const cv: any;
let loadedOpenCV = false;
let isCameraPermissionAccepted = false;

type VideoNormalizerProps = {
    currScanSide: string;
    nextPath?: string;
    shouldReinitialize?: boolean;
    docType?: "NA_ID" | "Passport";
};

function VideoNormalizerUI({ currScanSide, nextPath, shouldReinitialize = true, docType = "NA_ID" }: VideoNormalizerProps) {
    const normalizer: MutableRefObject<CaptureVisionRouter | null> = useRef(null);
    const dce: MutableRefObject<CameraEnhancer | null> = useRef(null);
    const imageEditorView: MutableRefObject<ImageEditorView | null> = useRef(null);
    const layer: MutableRefObject<DrawingLayer | null> = useRef(null);
    const view: MutableRefObject<CameraView | null> = useRef(null);
    const items: MutableRefObject<Array<any>> = useRef([]);
    const image: MutableRefObject<DSImageData | null> = useRef(null);
    const imageEditorViewContainerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
    const cameraViewContainerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
    const normalizedImageContainer: MutableRefObject<HTMLDivElement | null> = useRef(null);
    const cameraEnhancer: MutableRefObject<Promise<CameraEnhancer> | null> = useRef(null);
    const router: MutableRefObject<Promise<CaptureVisionRouter> | null> = useRef(null);
    const [bShowUiContainer, setShowUiContainer] = useState(true);
    const [bShowImageContainer, setShowImageContainer] = useState(false);
    const [bShowLoading, setShowLoading] = useState(true);
    const showDynamicBorder = false;
    const hasInitialized = useRef<boolean>(false);
    const [showOverlay, setShowOverlay] = useState(false);

    const maxRetakes = process.env.REACT_APP_MAX_IMAGE_RETAKES ? Number(process.env.REACT_APP_MAX_IMAGE_RETAKES) : 3;
    const envFrameCount =
        docType === "Passport"
            ? (process.env.REACT_APP_DOC_NORM_FRAME_COUNT_THRESHOLD_PASSPORT as string)
            : (process.env.REACT_APP_DOC_NORM_FRAME_COUNT_THRESHOLD as string);
    const requiredFrameCount = useRef<number>(parseInt(envFrameCount));
    const confidenceEnv =
        docType === "Passport"
            ? (process.env.REACT_APP_DOC_NORM_CONFIDENCE_PASSPORT as string)
            : (process.env.REACT_APP_DOC_NORM_CONFIDENCE as string);
    const minConfidenceEnv =
        docType === "Passport"
            ? (process.env.REACT_APP_DOC_NORM_CONFIDENCE_MIN_PASSPORT as string)
            : (process.env.REACT_APP_DOC_NORM_CONFIDENCE_MIN as string);
    const minRequiredConfidence = useRef<number>(parseInt(minConfidenceEnv));
    const requiredConfidencePercent = useRef<number>(parseInt(confidenceEnv));
    const areaEnv = process.env.REACT_APP_DOC_NORM_AREA_VALUE as string;
    const requiredAreaVal = useRef<number>(parseInt(areaEnv));

    const navigate = useNavigate();
    const [ingestFrontImage] = useIngestFrontImageMutation();
    const [ingestBackImage] = useIngestBackImageMutation();
    const [postStatusUpdateAzure] = usePostStatusUpdateAzureMutation();
    const { logEvent } = useLogEvent();

    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements, token, routerVersion, language } = user;
    const url = `/${getNextPage(captureRequirements, currScanSide)}?token=${token}&version=${routerVersion}&language=${language}`;

    const [captureQualityStatus, setCaptureQualityStatus] = useState<"high" | "medium" | "low" | "zero">("zero");
    const [retakeCount, setRetakeCount] = useState<number>(0);

    const encodedImageData = useRef<string>("");
    const hasCapturedImage = useRef(false);
    const shouldSharpen = useRef<boolean>(true);
    const continueBtnDisabled = useRef<boolean>(false);
    const numGoodFrames = useRef<number>(0);
    const numBadConfidenceFrames = useRef<number>(0);
    const numBadAreaFrames = useRef<number>(0);
    const numBadFrames = useRef<number>(0);
    const numNoQuadFrames = useRef<number>(0);
    const currCropRegion = useRef<any>();

    const init = async () => {
        try {
            view.current = await CameraView.createInstance();
            dce.current = await (cameraEnhancer.current = CameraEnhancer.createInstance(view.current));

            view?.current && view.current.setScanLaserVisible(false);

            const scanRegion = {
                x: 7,
                y: 15,
                width: 86,
                height: 32,
                isMeasuredInPercentage: true,
            };

            dce.current.setScanRegion(scanRegion);
            view.current.setVideoFit("cover");
            await dce.current.setResolution({
                width: 1920,
                height: 1080,
            });

            const scanRegionStyle = {
                strokeStyle: "white",
            };
            view.current.setScanRegionMaskStyle(scanRegionStyle);
            // console message index = 34

            imageEditorView.current = await ImageEditorView.createInstance(imageEditorViewContainerRef.current as HTMLDivElement);

            /* Creates an image editing layer for drawing found document boundaries. */
            layer.current = imageEditorView.current.createDrawingLayer();

            /**
             * Creates a CaptureVisionRouter instance and configure the task to detect document boundaries.
             * Also, make sure the original image is returned after it has been processed.
             */
            normalizer.current = await (router.current = CaptureVisionRouter.createInstance());
            normalizer.current.setInput(dce.current);
            /**
             * Sets the result types to be returned.
             * Because we need to normalize the original image later, here we set the return result type to
             * include both the quadrilateral and original image data.
             */
            let newSettings = await normalizer.current.getSimplifiedSettings("detect-document-boundaries");
            newSettings!.capturedResultItemTypes = EnumCapturedResultItemType.CRIT_DETECTED_QUAD | EnumCapturedResultItemType.CRIT_ORIGINAL_IMAGE;
            await normalizer.current.updateSettings("detect-document-boundaries", newSettings!);
            cameraViewContainerRef.current!.append(view.current.getUIElement());

            const message = document.getElementById("div-ui-container")?.children[0]?.shadowRoot?.querySelector(".dce-msg-poweredby") || null;
            if (message) {
                (message as any).style.display = "none";
            }

            /* Defines the result receiver for the task.*/
            const resultReceiver = new CapturedResultReceiver();

            /** Callback for each detected quadrilateral (ID) - fires rapidly */
            resultReceiver.onCapturedResultReceived = async (result) => {
                /** Prevent us from entering this logic while it's already running */

                /** Get the confidence of the current captured result received */
                const currConfidencePercent = (result?.items[1] as any)?.confidenceAsDocumentBoundary;

                /** Get the area of the current captured result (how much of the camera view it's taking up) */
                const currArea = (result?.items[1] as any)?.location?.area;

                /** Get the original image captured for this result received */
                let img = (result.items.filter((item) => item.type === EnumCapturedResultItemType.CRIT_ORIGINAL_IMAGE)[0] as OriginalImageResultItem)
                    ?.imageData;

                if (!img) {
                    // console message index = 35
                    return;
                }

                /** Get the scan region crop region to offset for document normalization with pixel coords on cropped image */
                let cropRegion = (img as DCEFrame).tag?.cropRegion;

                /**
                 * This gets the dynamic cropRegion values and creates a coordinate plane for the normalizer points to use
                 * This is intended to fix the undefined image bug
                 */
                currCropRegion.current = [
                    { x: Math.floor(cropRegion!.left / 10) * 10, y: Math.floor(cropRegion!.top / 10) * 10 },
                    { x: Math.ceil(cropRegion!.right / 10) * 10, y: Math.floor(cropRegion!.top / 10) * 10 },
                    { x: Math.ceil(cropRegion!.right / 10) * 10, y: Math.ceil(cropRegion!.bottom / 10) * 10 },
                    { x: Math.floor(cropRegion!.left / 10) * 10, y: Math.ceil(cropRegion!.bottom / 10) * 10 },
                ];
                image.current = img;

                let offsetL = 0,
                    offsetR = 0,
                    offsetB = 0,
                    offsetT = 0;
                if (cropRegion && cropRegion.isMeasuredInPercentage) {
                    // Do something about it when it is percentage
                    // console message index = 36
                } else {
                    /** Calculate offsets based on cropped scan region so doc norm pixel coords account for this when normalizing */
                    offsetL = cropRegion ? cropRegion.left : 0;
                    offsetR = cropRegion ? cropRegion.right : 0;
                    offsetB = cropRegion ? cropRegion.bottom : 0;
                    offsetT = cropRegion ? cropRegion.top : 0;
                }
                let detectedQuads = result.items.filter((item) => item.type === EnumCapturedResultItemType.CRIT_DETECTED_QUAD);
                if (!detectedQuads) {
                    // console message index = 37
                }
                items.current = detectedQuads;
                for (let i = 0; i < items.current.length; i++) {
                    let _points = items.current[i].location.points;
                    for (let o = 0; o < _points.length; o++) {
                        _points[o].x -= offsetL;
                        _points[o].y -= offsetT;
                    }
                    items.current[i].location.points = _points;
                }
                if (items.current.length === 0) {
                    /** ⛔️ No quad results */
                    numNoQuadFrames.current += 1;
                    if (numNoQuadFrames.current % 100 === 0) {
                        confidenceIsZero();
                    }
                    return;
                } else {
                    /** ✅ Detected quads */
                    const isConfidentEnough = currConfidencePercent >= requiredConfidencePercent.current;
                    const isLargeEnough = currArea >= requiredAreaVal.current;

                    /** Capture criteria met */
                    if (isConfidentEnough && isLargeEnough) {
                        /** * Confidence ✅ * Area ✅ */
                        confidenceIsHigh();
                        numGoodFrames.current++;

                        /** 🏆 Successfully met the capture criteria for the required number of frames */
                        if (numGoodFrames.current >= requiredFrameCount.current) {
                            // console message index = 38
                            numBadConfidenceFrames.current = 0;
                            numBadAreaFrames.current = 0;
                            numBadFrames.current = 0;
                            numGoodFrames.current = 0;
                            dce.current?.pause();
                            normalizer.current!.stopCapturing();
                            setShowUiContainer(false);
                            confidenceIsZero();
                            await normalize();
                        }
                    } else if (isConfidentEnough && !isLargeEnough) {
                        /** * Confidence ✅ * Area ⛔️ */
                        confidenceIsMedium();
                        numBadAreaFrames.current += 1;
                        const areaFrameFailureThreshold = 100;

                        if (numBadAreaFrames.current % areaFrameFailureThreshold === 0) {
                            requiredAreaVal.current -= 20000;
                        }
                    } else if (!isConfidentEnough && isLargeEnough) {
                        /** * Confidence ⛔️ * Area ✅ */
                        confidenceIsLow();
                        numBadConfidenceFrames.current += 1;
                        const confidenceFrameFailureThreshold = 50;

                        /** If the number of consecutive badFrames we've gotten since it's reset any time we get a 'good' frame  */
                        if (numBadConfidenceFrames.current % confidenceFrameFailureThreshold === 0) {
                            // console message index = 39
                            numGoodFrames.current = 0;
                            /** We have detected quad 100 time without meeting criteria - lower the criteria if it's not at the minimum already */
                            if (requiredConfidencePercent.current !== minRequiredConfidence.current) {
                                requiredConfidencePercent.current -= 5;
                                // console message index = 40
                            }
                        }
                    } else {
                        /** Confidence ⛔️ * Area ⛔️ */
                        numBadFrames.current += 1;
                        if (numBadFrames.current % 100 === 0) {
                            confidenceIsZero();
                        }
                    }
                }
            };
            normalizer.current.addResultReceiver(resultReceiver);

            // This is to prevent the jumpy camera on iOS devices due to the new minimal focus distance
            // see details here at this apple developer post: https://developer.apple.com/forums/thread/715568
            // switching to the ultra wide camera prevents it from trying to use the telephoto
            // and this prevent switching cameras
            const cameras = await dce.current?.getAllCameras();
            if (cameras && cameras.length) {
                const backCam = cameras.find((cam) => cam.label.toLowerCase().includes("back camera(hd)"));
                if (backCam) {
                    // if we have an ultrawide, we are on a iOS device, so set it.
                    await dce.current?.selectCamera(backCam);
                }
                // if we don't, let dynamsoft do the work of choosing the camera
            }

            await dce.current.open();

            /** Hide UI elements we don't want */
            setTimeout(() => {
                if (view && view.current) {
                    view.current.setScanLaserVisible(false);
                    hideDropDowns();
                    hideDynamicBorder();
                }
            }, 100);

            /* Uses the built-in template "detect-document-boundaries" to start a continuous boundary detection task. */
            await normalizer.current.startCapturing("detect-document-boundaries");
            setShowLoading(false);
            isCameraPermissionAccepted = true;
            setTimeout(() => {
                setShowOverlay(true);
            }, 500);
            view?.current && view.current.setScanLaserVisible(false);
        } catch (ex: any) {
            let errMsg: string;
            logEvent(CustJourneyCodes.captureFront.captureComponentInitError.status);
            if (ex.message.includes("network connection error")) {
                errMsg =
                    "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
                const statusUpdateBody = {
                    module: "IDN-UI-WelcomeScreen",
                    message: "Dynamsoft failed to initialize",
                    status: 1,
                    error: null,
                };
                postStatusUpdateAzure(statusUpdateBody);
            } else if (ex.message.includes("Permission denied")) {
                errMsg = "Camera permissions were denied.";
                const statusUpdateBody = {
                    module: "IDN-UI-WelcomeScreen",
                    message: "Permission denied",
                    status: 1,
                    error: null,
                };
                postStatusUpdateAzure(statusUpdateBody);
                navigate(`/camera-denied?token=${token}&version=${routerVersion}&language=${language}`);
            } else {
                errMsg = ex.message || ex;
            }
            // console message index = 41
        }
    };

    const normalize = async () => {
        /* Hides the imageEditorView. */
        setShowImageContainer(false);

        /* Removes the old normalized image if any. */
        normalizedImageContainer.current!.innerHTML = "";
        /**
         * Sets the coordinates of the ROI (region of interest)
         * in the built-in template "normalize-document".
         */
        let ss = (await normalizer.current!.getSimplifiedSettings("normalize-document")) as SimplifiedCaptureVisionSettings;
        ss.roiMeasuredInPercentage = false;
        /** Set hard-coded */

        /** Set normalization coords (x,y) coords for top-left, top-right, bottom-right, bottom-left points for cropping */
        ss.roi.points = currCropRegion.current; // This one is based on the users dynamic cropRegion but doesn't work for some reason
        ss.roi.points = [
            { x: 30, y: 30 },
            { x: 900, y: 30 },
            { x: 900, y: 600 },
            { x: 30, y: 600 },
        ];
        await normalizer.current!.updateSettings("normalize-document", ss);

        /* Executes the normalization and shows the result on the page */
        let norRes = null;
        if (image.current && image.current?.bytes?.length !== 0) {
            // console message index = 42
            try {
                norRes = await normalizer.current!.capture(image.current!, "normalize-document");
                // console message index = 43
            } catch (error) {
                // console message index = 44
                return;
            }
        } else {
            // empty because previously console log
            // console message index = 45
            // console message index = 46
        }

        const normalizedImageItem = norRes?.items[0] as NormalizedImageResultItem;

        if (normalizedImageItem) {
            if (shouldSharpen.current) {
                // console message index = 47
                const sharpenedImage = await sharpenImage(cv, normalizedImageItem?.toCanvas());
                /** Display the sharpened image in the image preview */
                normalizedImageContainer.current!.append(sharpenedImage);
                const imageUrl = sharpenedImage.toDataURL("image/jpeg");
                /** The image data to ingest is the sharpened B64 */
                encodedImageData.current = imageUrl.replace("data:image/jpeg;base64,", "");
            } else {
                // console message index = 48
                normalizedImageContainer.current!.append(normalizedImageItem?.toCanvas());
                /** Display the Unsharpened image int he image preview */
                const unsharpenedImageUrl = normalizedImageItem?.toCanvas()?.toDataURL("image/jpeg");
                /** The image data to ingest is the unsharpened B64 */
                encodedImageData.current = unsharpenedImageUrl?.replace("data:image/jpeg;base64,", "");
            }
        } else {
            // empty because previously console statements
            // console message index = 49
            // console message index = 50
        }

        layer.current!.clearDrawingItems();
        /* show video view */
        view.current!.getUIElement().style.display = "";
    };

    /** UI Methods */
    const hideDynamicBorder = () => {
        // Hide Dynamic ID Outline
        if (!showDynamicBorder) {
            let style: any = DrawingStyleManager.getDrawingStyle(1);
            style.strokeStyle = "rgba(73, 173, 0, 0)";
            DrawingStyleManager.updateDrawingStyle(1, style);
        }
    };

    const hideDropDowns = () => {
        const cameraSelElement = document.getElementById("div-ui-container")?.children[0]?.shadowRoot?.querySelector(".dce-sel-camera") || null;
        const resolutionSelElement =
            document.getElementById("div-ui-container")?.children[0]?.shadowRoot?.querySelector(".dce-sel-resolution") || null;
        if (cameraSelElement) {
            (cameraSelElement as any).style.display = "none";
        }
        if (resolutionSelElement) {
            (resolutionSelElement as any).style.display = "none";
        }
    };

    const confidenceIsHigh = () => {
        setCaptureQualityStatus("high");
    };

    const confidenceIsMedium = () => {
        setCaptureQualityStatus("medium");
    };

    const confidenceIsLow = () => {
        setCaptureQualityStatus("low");
    };

    const confidenceIsZero = () => {
        setCaptureQualityStatus("zero");
    };

    const handleContinueClicked = () => {
        handleCurrSideIngest(encodedImageData.current, currScanSide);

        if (nextPath && !shouldReinitialize) {
            /** When we don't want to re-initialize the document normalizer - just navigate to the nextPath prop value */
            // console message index = 51
            navigate(nextPath);
        } else {
            // console message index = 52
            /** Clear the normalized image result preview */
            normalizedImageContainer.current!.innerHTML = "";
            /** Show the camera container again */
            setShowUiContainer(true);
            /** Hide the image preview container */
            setShowImageContainer(false);
            /** Set has captured image back to false for next capture to enter onDetectedQuadsReceived */
            hasCapturedImage.current = false;
            navigate(url);
        }
    };

    /**
     * handleCurrSideImageIngest sends the appropriate base64 encoded image to the corresponding side endpoint in Olympic
     * @param imageData
     * @param currScanSide
     */
    const handleCurrSideIngest = (imageData: string, currScanSide: string) => {
        /** FRONT IMAGE */
        if (currScanSide.toLowerCase() === "front") {
            /** FRONT IMAGE */
            const frontImageReqBody = {
                frontImage: imageData,
            };
            ingestFrontImage(frontImageReqBody);
        } else {
            /** BACK IMAGE */
            const backImageReqBody = {
                backImage: imageData,
            };
            ingestBackImage(backImageReqBody);
        }
    };

    if (!hasInitialized.current) {
        hasInitialized.current = true;
        init();
    }

    useEffect((): any => {
        if (!loadedOpenCV) {
            loadedOpenCV = true;
            const script = document.createElement("script");
            script.src = "https://docs.opencv.org/master/opencv.js"; // CDN for opencv.js
            script.async = true;
            document.body.appendChild(script);
        }

        /** Post Azure Event Update - Started Front Capture */
        const statusUpdateBody = {
            module: "IDN-LicenseCaptureFront",
            message: "InProgress",
            status: 1,
            error: null,
        };
        postStatusUpdateAzure(statusUpdateBody);

        return async () => {
            (await router.current)!.dispose();
            (await cameraEnhancer.current)!.dispose();
            // console message index = 57
        };
    }, []);

    const retake = async () => {
        setShowOverlay(false);
        (await router.current)!.dispose();
        (await cameraEnhancer.current)!.dispose();
        init();

        setRetakeCount(retakeCount + 1);
        image.current = null;
        normalizedImageContainer.current!.innerHTML = "";
        hasCapturedImage.current = false;
        // encodedImageData.current = ''
        normalizer.current = null;
        view.current?.setScanLaserVisible(false);
        setShowImageContainer(false);
        setShowUiContainer(true);
        cameraViewContainerRef.current!.removeChild(view.current?.getUIElement() as Node);
    };

    const scanArea = document.getElementById("div-ui-container")?.children[0]?.shadowRoot?.querySelector(".dce-scanarea") || null;

    const scanBoxHeight = (scanArea as any)?.style?.height || "0px";
    const scanBoxWidth = (scanArea as any)?.style?.width || "0px";
    const leftPosition = (scanArea as any)?.style?.left || 0;
    const topPosition = (scanArea as any)?.style?.top || 0;

    return (
        <>
            {bShowUiContainer ? (
                <>
                    {isCameraPermissionAccepted && (
                        <>
                            <CaptureMessages page={currScanSide} docType={docType} captureQualityStatus={captureQualityStatus} />
                            {showOverlay && (
                                <img
                                    className='position-absolute'
                                    src={frontOverlay}
                                    alt='id overlay'
                                    style={{
                                        opacity: "0.2",
                                        height: scanBoxHeight,
                                        width: scanBoxWidth,
                                        zIndex: 100,
                                        left: leftPosition,
                                        top: topPosition,
                                    }}
                                />
                            )}
                        </>
                    )}
                </>
            ) : null}

            <div
                id='div-ui-container'
                style={{
                    display: bShowUiContainer ? "block" : "none",
                    height: "100vh",
                    width: "100vw",
                }}
                ref={cameraViewContainerRef}
            />
            <div
                id='div-image-container'
                style={{
                    display: bShowImageContainer ? "block" : "none",
                    width: "100vw",
                    height: "70vh",
                }}
                ref={imageEditorViewContainerRef}
            >
                <div
                    className='dce-image-container'
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                />
            </div>
            <div
                id='normalized-result'
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
                ref={normalizedImageContainer}
            />

            {image?.current &&
                !bShowUiContainer &&
                (!bShowLoading ? (
                    <CaptureButtons
                        page={currScanSide}
                        continueBtnDisabled={continueBtnDisabled.current}
                        retake={retake}
                        maxRetakes={maxRetakes}
                        retakeCount={retakeCount}
                        handleContinueClicked={handleContinueClicked}
                        docType={docType}
                    />
                ) : (
                    <div
                        style={{
                            display: "flex",
                            width: "100vw",
                            justifyContent: "center",
                            marginTop: "2rem",
                        }}
                    >
                        <Spinner />
                    </div>
                ))}
        </>
    );
}

export default VideoNormalizerUI;
