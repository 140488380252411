/**
 * Description: Configures and exports the Redux store for the application.
 * The store includes reducers, middleware, and state persistence settings.
 */
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import { api } from "../api/api";
import appSlice from "./slices/app.slice";
import userSlice from "./slices/user.slice";
import authIdSlice from "./slices/authId.slice";
import ipqsSlice from "./slices/ipqs.slice";
import acmeSlice from "./slices/acme.slice";

/**
 * Combines all the reducers, including the API slice, into a single root reducer.
 */
const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    app: appSlice,
    user: userSlice,
    authId: authIdSlice,
    ipqs: ipqsSlice,
    acme: acmeSlice,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (gDM) =>
        gDM({
            serializableCheck: {},
        }).concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
