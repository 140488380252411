import React, { useEffect } from "react";
import VideoDecode from "../components/VideoDecoder/BarcodeScanner/VideoDecode";
import useLogEvent from "../hooks/useLogEvent";
import { getCompanyConfig } from "../utils/getCompanyConfig";
import ErrorBoundaryWrapper from "../components/ErrorBoundary/ErrorBoundaryWrapper";
import CustJourneyCodes from "../assets/CustomerJourneyCodes.json";

const BarcodePage = () => {
    const { logEvent } = useLogEvent();
    const barcodeCaptureComponent = getCompanyConfig("barcodeCaptureComponent");

    // Fire status message when user navigates to this page
    useEffect(() => {
        // logEvent("User navigated to barcode capture screen");
        logEvent(CustJourneyCodes.captureBarcode.navToPage.status);
    }, []);

    return <ErrorBoundaryWrapper>{barcodeCaptureComponent ? barcodeCaptureComponent : <VideoDecode />}</ErrorBoundaryWrapper>;
};

export default BarcodePage;
