import React, { MutableRefObject } from "react";
import { Modal, Spinner } from "react-bootstrap";

interface ModalProps {
    showModal: boolean;
    message: string;
}

const LoadingModal = ({ showModal, message }: ModalProps) => {
    return (
        <>
            {showModal && (
                <Modal show={true} className='mt-5'>
                    <div className='modal-overlay flex-column justify-content-center align-items-center p-4'>
                        <div className='modal-content flex-column justify-content-center align-items-center p-3'>
                            <p>{message}</p>
                            <Spinner />
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default LoadingModal;
