import React from "react";
import { motion, AnimatePresence } from "framer-motion";
interface SlideTransitionProps {
    children: React.ReactNode;
}

export const pageTransition = {
    init: {
        opacity: 0,
        x: "100vw",
    },
    in: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.55,
        },
    },
    out: {
        opacity: 0,
        x: "-100vw",
        transition: {
            duration: 0.55,
        },
    },
};

const SlideTransition = ({ children }: SlideTransitionProps) => {
    return (
        <AnimatePresence mode='wait'>
            <motion.div initial='init' animate='in' exit='out' variants={pageTransition}>
                {children}
            </motion.div>
        </AnimatePresence>
    );
};
export default SlideTransition;
