import React, { useEffect } from "react";
import BackgroundAndLogo from "../../components/BackgroundAndLogo/BackgroundAndLogo";
import useLogEvent from "../../hooks/useLogEvent";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import CustJourneyCodes from "../../assets/CustomerJourneyCodes.json";

const BiometricsDenied = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { logEvent } = useLogEvent();

    // Fire status message when user navigates to this page
    useEffect(() => {
        // logEvent("User navigated to biometrics denied page");
        logEvent(CustJourneyCodes.errors.biometricsDenied.navToPage.status);
    }, []);

    const clickBack = () => {
        // logEvent("User clicked Go back Button on biometrics denied");
        logEvent(CustJourneyCodes.errors.biometricsDenied.clickBack.status);
        navigate(-1);
    };

    return (
        <BackgroundAndLogo>
            <div className='h-100 w-100 d-flex flex-column justify-content-start align-items-center mt-5'>
                <h3 className='text-center mb-5'>{t("biometricsDeniedPage.areYouSure")}</h3>
                <h3 className='text-center mb-5'>{t("biometricsDeniedPage.backOrClose")}</h3>
                <Button onClick={clickBack}>{t("biometricsDeniedPage.backTo")}</Button>
            </div>
        </BackgroundAndLogo>
    );
};

export default BiometricsDenied;
