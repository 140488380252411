import { useState, useEffect, useRef } from "react";
import { useIngestBarcodeDataMutation } from "../../../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Button, Spinner } from "react-bootstrap";
import BackgroundAndLogo from "../../../components/BackgroundAndLogo/BackgroundAndLogo";

const AcmeResultsComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { search } = location;
    const urlParams = new URLSearchParams(search);
    const url = `/complete?${urlParams}`;

    const isSimData = process.env.REACT_APP_SIMULATED;

    const [caughtErrorMessage, setCaughtErrorMessage] = useState("");
    const [ingestBarcodeData, barcodeResponse] = useIngestBarcodeDataMutation();
    const errorCaught = useRef<boolean>(false);
    const hasSubmittedBarcode = useRef<boolean>(false);

    if (!hasSubmittedBarcode.current) {
        const submitBarcodeBody = location.state.submitBarcodeBody;
        ingestBarcodeData(submitBarcodeBody);
        hasSubmittedBarcode.current = true;
    }

    useEffect(() => {
        if (barcodeResponse.isError) {
            navigate("/error");
        }
    }, [barcodeResponse]);

    useEffect(() => {
        if (caughtErrorMessage) {
            navigate("/error");
        }
    }, [caughtErrorMessage]);

    if (barcodeResponse && barcodeResponse.isSuccess && barcodeResponse.data && barcodeResponse.data.payload) {
        try {
            const acmeParsed = JSON.parse(barcodeResponse.data.payload);

            const {
                private_data: {
                    result: { FirstName, LastName, Address1, City, DateOfBirth, PostalCode, State },
                },
            } = acmeParsed;

            const results = [
                {
                    label: "First Name",
                    value: FirstName,
                },
                {
                    label: "Last Name",
                    value: LastName,
                },
                {
                    label: "Date of Birth",
                    value: DateOfBirth,
                },
                {
                    label: "Address",
                    value: Address1,
                },
                {
                    label: "City",
                    value: City,
                },
                {
                    label: "State",
                    value: State,
                },
                {
                    label: "Zip",
                    value: PostalCode,
                },
            ];

            return (
                <BackgroundAndLogo>
                    <Alert variant='success' className='w-100 text-center'>
                        <>
                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#19c567' className='me-2' viewBox='0 0 16 16'>
                                <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
                            </svg>
                            {isSimData ? "Presto!" : "Success"}
                        </>
                    </Alert>
                    <p className='text-left'>
                        {isSimData ? "Here are your identity validation results." : "We have pre-populated the information from your ID below:"}
                    </p>
                    <div className='d-flex flex-column vw-100 align-items-center'>
                        {results.map((value: any, index: number) => (
                            <div key={`${index}_result`} className='d-flex flex-row w-100 gap-3 px-1'>
                                <div className='d-flex col-5 justify-content-end align-items-center'>
                                    <label htmlFor='usr' className='form-label fw-bold'>
                                        {value?.label}:{" "}
                                    </label>
                                </div>
                                <div className='d-flex col-7'>
                                    <label className='form-label text-start' id='usr'>
                                        {value?.value}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Button className='w-25 mt-3' variant='success' onClick={() => navigate(url)}>
                        Continue
                    </Button>

                    {isSimData ? (
                        <>
                            <p className='mt-3 ms-2'>
                                Results on this page are simulated. Actual results contain more details. Results will be posted to a URL of your
                                choice.
                            </p>
                            <p className='position-sticky bottom-0 mb-0'>Simulator does not collect, store, or submit data.</p>
                        </>
                    ) : null}
                </BackgroundAndLogo>
            );
        } catch (ex: any) {
            if (!errorCaught.current) {
                errorCaught.current = true;
                setCaughtErrorMessage(`AcmeResultsComponent error: ${ex?.message}`);
            }
            return <></>;
        }
    } else {
        return (
            <BackgroundAndLogo>
                <Spinner />
            </BackgroundAndLogo>
        );
    }
};

export default AcmeResultsComponent;
