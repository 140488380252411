import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getNextPage } from "../../redux/slices/user.slice";
import { useLocation } from "react-router-dom";

type OwnProps = {
    numSteps: number;
};

const Stepper: React.FC = () => {
    const user = useSelector((state: RootState) => state.user);
    const location = useLocation();
    const currentPathname = location.pathname;
    const { captureRequirements } = user;

    const nextPage = getNextPage(captureRequirements, currentPathname.split("/")[1]);
    let nextIndex = captureRequirements.indexOf(nextPage);
    const currentIndex = nextIndex - 1;

    if (nextIndex == -1) {
        // if the next index is out of range, we are on the last page. set the next to the length of the array.
        nextIndex = captureRequirements.length;
    }

    const stepsArray: { isFilled: boolean }[] = Array(captureRequirements.length).fill({ isFilled: false }).fill({ isFilled: true }, 0, nextIndex);

    if (!captureRequirements.includes(currentPathname.split("/")[1])) {
        // don't display the stepper if we aren't on the capture screens
        return null;
    }

    if (captureRequirements.length <= 1) {
        // don't display the stepper if there is only one step
        return null;
    }

    const stepperWidth = captureRequirements.length <= 2 ? 3 : 5;

    return (
        <Container>
            <Row className='d-flex justify-content-center'>
                <Col xs={stepperWidth} className='d-flex justify-content-between'>
                    {stepsArray.map((step, index) => (
                        <StepperCircle isFilled={step.isFilled} key={index} isActive={currentIndex === index} />
                    ))}
                </Col>
            </Row>
        </Container>
    );
};

type CircleProps = {
    isFilled?: boolean;
    isActive?: boolean;
};

const StepperCircle: React.FC<CircleProps> = ({ isFilled, isActive }) => {
    return (
        <>
            {isFilled ? <i className={`${isActive ? "text-warning" : "text-white"} bi bi-circle-fill`} /> : <i className='text-white bi bi-circle' />}
        </>
    );
};

export default Stepper;
