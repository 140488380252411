import React, { useEffect } from "react";
import BackgroundAndLogo from "../../components/BackgroundAndLogo/BackgroundAndLogo";
import useLogEvent from "../../hooks/useLogEvent";
import { useTranslation } from "react-i18next";
import CustJourneyCodes from "../../assets/CustomerJourneyCodes.json";

const ErrorPage = () => {
    const { logEvent } = useLogEvent();
    const { t } = useTranslation();

    // Fire status message when user navigates to this page
    useEffect(() => {
        // logEvent("User navigated to error page");
        logEvent(CustJourneyCodes.errors[404].navToPage.status);
    }, []);

    return (
        <BackgroundAndLogo>
            <div className='h-100 w-100 d-flex flex-column justify-content-start align-items-center mt-5'>
                <h3 className='text-center'>{t("errorPage")}</h3>
            </div>
        </BackgroundAndLogo>
    );
};

export default ErrorPage;
