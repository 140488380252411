import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export type OwnProps = {
    docType: "NA_ID" | "Passport";
    page: string;
    captureQualityStatus: "high" | "medium" | "low" | "zero";
};

const CaptureMessages: React.FC<OwnProps> = ({ docType, page, captureQualityStatus }) => {
    const { t } = useTranslation();
    const defaultScanSideMessage =
        docType === "Passport"
            ? t("captureMessages.scanPassport")
            : t("captureMessages.scanIdBySide", { side: page.toLowerCase() === "front" ? t("captureMessages.front") : t("captureMessages.back") });

    const defaultInstructionMessage = t("captureMessages.positionYourId", {
        document: docType === "Passport" ? t("captureMessages.passport") : t("captureMessages.id"),
    });
    const [instructionMessage, setInstructionMessage] = useState<string | React.ReactNode>(defaultInstructionMessage);
    const [changeMessage, setChangeMessage] = useState<boolean>(true);

    const frontMessageId = t("captureMessages.frontMessageId");
    const frontMessagePassport = t("captureMessages.frontMessagePassport");

    const frontMessages = docType === "Passport" ? frontMessagePassport : frontMessageId;
    const backMessage = t("captureMessages.backMessage");

    const defaultAdditionalMessage = page.toLowerCase() === "front" ? frontMessages : backMessage;
    const [additionalMessage, setAdditionalMessage] = useState<string>("");

    const statusMessages = {
        zero: defaultInstructionMessage,
        low: (
            <Badge className='p-2 fs-5' bg='danger'>
                {t("captureMessages.alignEdges")}
            </Badge>
        ),
        medium: (
            <Badge className='p-2 fs-5' bg='warning'>
                {t("captureMessages.holdCameraStill")}
            </Badge>
        ),
        high: (
            <Badge className='p-2 fs-5' bg='success '>
                <i className='bi bi-check me-1' />
                {t("captureMessages.capturing")}
            </Badge>
        ),
    };

    // Display the additional message after 7.5 seconds
    useEffect(() => {
        setTimeout(() => {
            setAdditionalMessage(defaultAdditionalMessage);
        }, 7500);
    }, []);

    useEffect(() => {
        if (changeMessage) {
            setInstructionMessage(statusMessages[captureQualityStatus]);
            setChangeMessage(false);
        } else {
            setTimeout(() => {
                setChangeMessage(true);
            }, 1000);
        }
    }, [captureQualityStatus]);

    return (
        <>
            <>
                <div
                    className='text-warning text-center position-absolute'
                    style={{
                        width: "100vw",
                        zIndex: 100,
                        top: "5vh",
                    }}
                >
                    <h3>{defaultScanSideMessage}</h3>
                </div>
                <div
                    className='text-warning text-center position-absolute'
                    style={{
                        width: "100vw",
                        zIndex: 100,
                        bottom: "40vh",
                    }}
                >
                    {instructionMessage}
                </div>
                {additionalMessage ? (
                    <div
                        className='text-warning text-center position-absolute'
                        style={{
                            width: "100vw",
                            zIndex: 100,
                            bottom: "25vh",
                        }}
                    >
                        {additionalMessage}
                    </div>
                ) : null}
            </>
        </>
    );
};

export default CaptureMessages;
