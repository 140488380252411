import React from "react";
import { useTranslation } from "react-i18next";

const FlexitiLanding = () => {
    const { t } = useTranslation();

    return (
        <div className='d-flex justify-content-center align-items-center flex-column text-center position-relative'>
            <h1 className='mt-4'>{t("flexiti.landingText1")}</h1>
            <p className='mt-5 mb-5'>{t("genericLandingPageText.text2")}</p>
            <p className='mt-5 mb-5'>{t("genericLandingPageText.text3")}</p>
        </div>
    );
};

export default FlexitiLanding;
