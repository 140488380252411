import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setUserObject } from "../../redux/slices/user.slice";
import { useNavigate } from "react-router-dom";
import { getCompanyConfig } from "../../utils/getCompanyConfig";
import { useTranslation } from "react-i18next";

/**
 * TypeScript element that gets the expirationTime value from the userConfig and defaults the remainingTime to that value before decrementing down, updating localstorage with the value to persist across redirect/refreshes.
 *
 * @returns TSX Countdown Timer element
 */
const CountdownTimer = () => {
    const { t } = useTranslation();
    const tokenExpirationTime = useSelector((state: RootState) => state.user.tokenExpirationTime);
    const landingTimeStamp = useSelector((state: RootState) => state.user.landingTimeStamp);
    const isTokenValid = useSelector((state: RootState) => state.user.isTokenValid);
    const currRouterVersion = useSelector((state: RootState) => state.app.routerVersion);
    const [shouldRender, setShouldRender] = useState<boolean>(currRouterVersion !== "truist");
    const dispatch = useDispatch();
    let secondsUntilTokenExpiration = Math.floor((tokenExpirationTime && tokenExpirationTime / 1000) || -1);
    const totalTimeSeconds = 600; // 10 minutes default
    let remainingTimeSeconds = totalTimeSeconds - secondsUntilTokenExpiration;
    const [timeRemaining, setTimeRemaining] = useState<number>(secondsUntilTokenExpiration);

    const [textColor, setTextColor] = useState<string>("text-dark");
    const [backgroundColor, setBackgroundColor] = useState<string>("");
    const [timerOpacity, setTimerOpacity] = useState<string>("");
    const currUrlPath = window.location.pathname;
    const { token, routerVersion, language } = useSelector((state: RootState) => state.user);
    const url = `/token-expired?token=${token}&version=${routerVersion}&language=${language}`;
    const navigate = useNavigate();

    const hideCountdownTimer = getCompanyConfig("hideCountdownTimer");

    useEffect(() => {
        const shouldSetLight = currUrlPath === "/front" || currUrlPath === "/back" || currUrlPath === "/barcode";
        if (shouldSetLight) {
            setTextColor("text-light");
            setBackgroundColor("rgba(0, 0, 0, 0.25)");
        } else {
            setTextColor("text-dark");
            setBackgroundColor("");
        }
        if (currUrlPath === "/complete") {
            setShouldRender(false);
        }
        if (hideCountdownTimer) {
            setShouldRender(false);
        }
    }, [currUrlPath, shouldRender, currRouterVersion, hideCountdownTimer]);

    useEffect(() => {
        if (landingTimeStamp && tokenExpirationTime) {
            const currentTime = new Date();
            const elapsedMilliSeconds = currentTime.getTime() - new Date(landingTimeStamp).getTime();
            if (elapsedMilliSeconds >= tokenExpirationTime) {
                // console message index = 2
                dispatch(setUserObject({ isTokenValid: false }));
            }
            const secondsUntilExpiration = Math.floor((tokenExpirationTime - elapsedMilliSeconds) / 1000);
            setTimeRemaining(secondsUntilExpiration);
        }

        const interval = setInterval(() => {
            setTimeRemaining((prevTime) => Math.max(0, prevTime - 1));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [remainingTimeSeconds, landingTimeStamp, tokenExpirationTime, dispatch]);

    useEffect(() => {
        if (timeRemaining === 0) {
            // (App.tsx useEffect dependency causing re-render and screen to display)
            dispatch(setUserObject({ isTokenValid: false }));
            navigate(url);
        }
    }, [timeRemaining, dispatch]);

    return (
        <>
            {shouldRender ? (
                <div
                    className={`mt-4 d-flex justify-content-center vw-100 z-3`}
                    style={{
                        backgroundColor: `${backgroundColor}`, // Brandy Approved!
                    }}
                >
                    {landingTimeStamp && timeRemaining && isTokenValid ? (
                        <h2 className={`${textColor} text-end pe-2 fs-5 mt-2`}>
                            {Math.floor(timeRemaining / 60)}:{(timeRemaining % 60).toString().padStart(2, "0")}
                        </h2>
                    ) : (
                        <h2 className={`${textColor} text-end pe-2 fs-5 mt-2`}>0:00</h2>
                    )}
                    <h2 className={`${textColor} ms-3 fs-5 mt-2`}>{t("expirationTimer")}</h2>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default CountdownTimer;
