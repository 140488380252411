import { useEffect, useRef } from "react";
import DeviceFingerprint from "node_js_ipqs_device_tracker";
import { useIngestIpqsTrackerIdMutation } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { updateHasProcessedIpqs } from "../../redux/slices/ipqs.slice";

const IpqsTracker = () => {
    const [ingestIpqsTrackerId] = useIngestIpqsTrackerIdMutation();
    const hasProcessedIpqs = useSelector((state: RootState) => state.ipqs.hasProcessedIpqs);
    const currRouterVersion = useSelector((state: RootState) => state.app.routerVersion);
    const dispatch = useDispatch();

    const createUniqueID = () => {
        return Date.now().toString(36) + Math.random().toString(36).substring(2);
    };

    const trackerId: any = createUniqueID();

    const handleIPQSResult = (result: any) => {
        // console  message index = 3
        const ipqsPayload = { ipqsTrackerId: trackerId };
        // console message index = 7
        ingestIpqsTrackerId(ipqsPayload).then((response: any) => {
            // console message index = 4
        });
        dispatch(updateHasProcessedIpqs(true));
    };

    const handleIPQSFailure = (result: any) => {
        // empty because of previous console statement
        // console message index = 5
    };

    /** IPQS Ingestion */
    useEffect(() => {
        if (!hasProcessedIpqs) {
            const ipqsKey = process.env.REACT_APP_IPQS_KEY || "";
            DeviceFingerprint.initializeScriptAsync(ipqsKey)
                .then(() => {
                    DeviceFingerprint.AfterResult(handleIPQSResult as () => void);
                    DeviceFingerprint.AfterFailure(handleIPQSFailure as () => void);
                    DeviceFingerprint.Store("transactionID", trackerId);
                    DeviceFingerprint.Init();
                })
                .catch(() => {
                    // empty because of previous console statement
                    // console message index = 6
                });
        }
    }, [currRouterVersion, hasProcessedIpqs]);

    return <></>;
};

export default IpqsTracker;
