import { useDispatch, useSelector } from "react-redux";
import { useSendLogEventMutation } from "../api/api";
import { incrementSequenceNumber } from "../redux/slices/app.slice";

const useLogEvent = () => {
    const [sendLogEvent] = useSendLogEventMutation();
    // const currSequenceNum = useSelector((state: any) => state.app.sequenceNumber);
    // const dispatch = useDispatch();

    const logEvent = (event: number): void => {
        const url = window.location;
        const userAgent = navigator.userAgent;

        const statusReportBody = {
            url: JSON.stringify(url),
            event: event,
            userAgent,
            // sequenceNumber: currSequenceNum,
        };

        sendLogEvent(statusReportBody);
        // dispatch(incrementSequenceNumber());
    };

    return { logEvent };
};

export default useLogEvent;
