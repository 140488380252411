import React, { useEffect } from "react";
import VideoNormalizerUI from "../components/VideoNormalizer/IDCapture/VideoNormalizerUI";
import useLogEvent from "../hooks/useLogEvent";
import ErrorBoundaryWrapper from "../components/ErrorBoundary/ErrorBoundaryWrapper";
import CustJourneyCodes from "../assets/CustomerJourneyCodes.json";

const BackPage = () => {
    const { logEvent } = useLogEvent();

    // Fire status message when user navigates to this page
    useEffect(() => {
        // logEvent("User navigated to back capture page");
        logEvent(CustJourneyCodes.captureBack.navToPage.status);
    }, []);

    return (
        <ErrorBoundaryWrapper>
            <VideoNormalizerUI currScanSide={"Back"} />
        </ErrorBoundaryWrapper>
    );
};
export default BackPage;
