import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getNextPage } from "../../redux/slices/user.slice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export type OwnProps = {
    continueBtnDisabled: boolean;
    retake: () => void;
    maxRetakes: number;
    retakeCount: number;
    handleContinueClicked: () => void;
    docType: "NA_ID" | "Passport";
    page: string;
};

const CaptureButtons: React.FC<OwnProps> = ({ handleContinueClicked, continueBtnDisabled, retake, maxRetakes, retakeCount, docType, page }) => {
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements, token, routerVersion, language } = user;
    const navigate = useNavigate();
    const url = `/${getNextPage(captureRequirements, page)}?token=${token}&version=${routerVersion}&language=${language}`;

    return (
        <>
            <div className='d-flex justify-content-center gap-4 mt-4'>
                <Button onClick={() => retake()} variant={maxRetakes === retakeCount ? "secondary" : "warning"} disabled={maxRetakes === retakeCount}>
                    {t("captureButtons.retake")}
                </Button>
                <Button disabled={continueBtnDisabled} onClick={() => handleContinueClicked()} variant='primary'>
                    {t("captureButtons.continue")}
                </Button>
            </div>
            <div
                className='text-black text-center mt-5'
                style={{
                    width: "100vw",
                }}
            >
                {continueBtnDisabled ? (
                    <p>
                        {t("captureButtons.failedFindBarcode")}
                        <b>{t("captureButtons.retake")}</b>
                    </p>
                ) : (
                    <>
                        <p>
                            {t("captureButtons.clearAndInFrame", {
                                document: docType === "Passport" ? t("captureButtons.passport") : t("captureButtons.id"),
                            })}
                            <b>{t("captureButtons.continue")}</b>.
                        </p>
                        <p>
                            {t("captureButtons.otherwiseRetake")}
                            <b>{t("captureButtons.retake")}</b>.
                        </p>
                    </>
                )}

                {retakeCount === maxRetakes - 1 ? <p className='text-danger'>{t("captureButtons.oneMoreRetake")}</p> : null}
                {retakeCount === maxRetakes ? <p className='text-danger'>{t("captureButtons.noRetakes")}</p> : null}
            </div>
        </>
    );
};

export default CaptureButtons;
