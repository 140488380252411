import { TrackJS } from "trackjs";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import ErrorBoundaryWrapper from "./components/ErrorBoundary/ErrorBoundaryWrapper";
import StylesheetWrapper from "./StylesheetWrapper";

import App from "./App";
import "./i18n/config";
/** Dyanmsoft Imports */
import "./components/VideoNormalizer/dce";
import "./components/VideoNormalizer/cvr";
import "./components/VideoRecognizer/dlr";
import AcmeApp from "./AcmeApp";

if (process.env.REACT_APP_TRACK_JS_ON?.toLowerCase() === "true") {
    TrackJS.install({
        token: process.env.REACT_APP_TRACKJS_TOKEN || "",
        application: process.env.REACT_APP_TRACKJS_APPLICATION || "capture",
        console: { enabled: false },
    });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

if (process.env.REACT_APP_DEFAULT_EXPERIENCE === "acme") {
    root.render(
        // <React.StrictMode>
        <Provider store={store}>
            <Router>
                <AcmeApp />
            </Router>
        </Provider>,
        // </React.StrictMode>
    );
} else {
    root.render(
        // <React.StrictMode>
        <Provider store={store}>
            <Router>
                <ErrorBoundaryWrapper>
                    <StylesheetWrapper>
                        <App />
                    </StylesheetWrapper>
                </ErrorBoundaryWrapper>
            </Router>
        </Provider>,
        // </React.StrictMode>
    );
}
