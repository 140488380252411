import { createSlice } from "@reduxjs/toolkit";

type AcmeState = {
    barcodeScanResults: any;
};

const initialState: AcmeState = {
    barcodeScanResults: undefined,
};

const acmeSlice = createSlice({
    name: "acme",
    initialState,
    reducers: {
        setBarcodeScanResults: (state, action) => {
            return {
                ...state,
                barcodeScanResults: action.payload,
            };
        },
    },
});

export const { setBarcodeScanResults } = acmeSlice.actions;

export default acmeSlice.reducer;
