import { Button, Stack } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";
import { setUserObject } from "../../../redux/slices/user.slice";
import BackgroundAndLogo from "../../../components/BackgroundAndLogo/BackgroundAndLogo";
import useLogEvent from "../../../hooks/useLogEvent";
import { useEffect } from "react";

const AcmeLandingPage = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(search);
    const dispatch = useDispatch();

    const { logEvent } = useLogEvent();

    // Fire status message when user navigates to this page
    useEffect(() => {
        // logEvent("User has open the text link and navigated to the landing page");
        logEvent(CustJourneyCodes.landing.openTextLink.status);
    }, []);

    const removeProcessRequirements = (route: string, reqsToRemove: any) => {
        const allCaptureReqs = ["front", "barcode", "selfie"];
        navigate(`/${route}?${urlParams.toString()}`);
        const updatedCaptureReqs = allCaptureReqs.filter((req) => !reqsToRemove.includes(req));
        dispatch(setUserObject({ captureRequirements: updatedCaptureReqs }));
    };

    const isSimData = process.env.REACT_APP_SIMULATED;

    return (
        <BackgroundAndLogo>
            <div className='container h-75 d-flex flex-column justify-content-center align-items-center'>
                <div className='d-flex flex-column text-center mb-5'>
                    {isSimData ? (
                        <>
                            <h1 className='fw-bold'>Intellicheck® Capture Simulator</h1>
                            <h3 className='mt-5'>By choosing an option below, you can get a feel for the capture experience.</h3>
                            <h3 className='mt-1'>Or in this case, a simulation, but you’ll get the idea.</h3>
                            <h3 className='mt-3 fw-bold'>Tap to continue.</h3>
                        </>
                    ) : (
                        <>
                            <h1 className='fw-bold'>Welcome to the Acme Company Application!</h1>
                            <h3 className='mt-5'>You will now verify your ID with Intellicheck®.</h3>
                        </>
                    )}
                </div>
                <div className='mb-5' />
                <Stack gap={3}>
                    <Button variant='success' onClick={() => removeProcessRequirements("barcode", ["front", "selfie"])}>
                        Verify Barcode
                    </Button>
                    <Button variant='success' onClick={() => removeProcessRequirements("front", ["selfie"])}>
                        Verify ID
                    </Button>
                    <Button variant='success' onClick={() => removeProcessRequirements("front", [])}>
                        Validate ID with Selfie
                    </Button>
                </Stack>
                {isSimData ? <p className='position-sticky bottom-0 mb-0'>Simulator does not collect, store, or submit data.</p> : null}
            </div>
        </BackgroundAndLogo>
    );
};

export default AcmeLandingPage;
