import { BarcodeScanner } from "dynamsoft-javascript-barcode";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./VideoDecode.css";
import { Base64 } from "js-base64";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import barcodeOverlay from "../../../assets/barcode.png";
import "./dbr";
import { useIngestBarcodeDataMutation, usePostStatusUpdateAzureMutation } from "../../../api/api";
import { RootState } from "../../../redux/store";
import { getNextPage } from "../../../redux/slices/user.slice";

type videoDecodeProps = {
    nextPath?: any;
};

const VideoDecode = ({ nextPath }: videoDecodeProps) => {
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const pScanner = useRef<Promise<BarcodeScanner> | null>(null);
    const elRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const hasSubmittedBarcode = useRef(false);
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements, token, routerVersion, language } = user;
    const url = nextPath ? `/${nextPath}?${urlParams}` : `/${getNextPage(captureRequirements, "barcode")}?${urlParams}`;
    const navigate = useNavigate();
    // console message index = 8

    const [ingestBarcodeData] = useIngestBarcodeDataMutation();
    const [postStatusUpdateAzure] = usePostStatusUpdateAzureMutation();

    const initScanner = async () => {
        try {
            const scanner = await (pScanner.current = BarcodeScanner.createInstance());

            /** Dynamsoft provided barcode scanner setting from JR */
            await scanner.updateRuntimeSettings("speed");
            const runtimeSettings = await scanner?.getRuntimeSettings();
            runtimeSettings.barcodeFormatIds = 0x02000000;
            runtimeSettings.barcodeFormatIds_2 = 0;
            runtimeSettings.expectedBarcodesCount = 1;
            runtimeSettings.furtherModes.grayscaleTransformationModes = [2, 0, 0, 0, 0, 0, 0, 0];
            runtimeSettings.localizationModes = [16, 8, 2, 0, 0, 0, 0, 0];
            runtimeSettings.deblurLevel = 9;
            runtimeSettings.minResultConfidence = 0;
            await scanner.updateRuntimeSettings(runtimeSettings);

            if (scanner.isContextDestroyed()) return;

            await scanner.setUIElement(elRef.current!);

            if (scanner.isContextDestroyed()) return;

            scanner.onUniqueRead = (txt, result) => {
                if (process.env.REACT_APP_DEFAULT_EXPERIENCE === "acme") {
                    const encodedBarcode = Base64.encode(txt);
                    const submitBarcodeBody = {
                        barcodeData: encodedBarcode,
                    };
                    navigate(url, { state: { submitBarcodeBody } });
                } else if (!hasSubmittedBarcode.current) {
                    const encodedBarcode = Base64.encode(txt);
                    const submitBarcodeBody = {
                        barcodeData: encodedBarcode,
                    };
                    // console message index = 10
                    ingestBarcodeData(submitBarcodeBody);
                    hasSubmittedBarcode.current = true;
                    navigate(url);
                }
            };

            await scanner.open();
        } catch (ex: any) {
            if (ex.message.indexOf("network connection error")) {
                let customMsg =
                    "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
                // console message index = 11
                const statusUpdateBody = {
                    module: "IDN-BarcodeScanner-002",
                    message:
                        "Barcode scanner error. Failed to connect to the Dynamsoft License Server: network timed out. Check your Internet connection or contact the site administrator for more information.",
                    status: 2,
                    error: null,
                };
                postStatusUpdateAzure(statusUpdateBody);
            }
            throw ex;
        }
    };

    useEffect(() => {
        initScanner();

        /** Post Azure Event Update - Started Barcode Capture */
        const statusUpdateBody = {
            module: "IDN-BarcodeScanner-002",
            message: "InProgress",
            status: 2,
            error: null,
        };
        postStatusUpdateAzure(statusUpdateBody);

        return () => {
            const cleanup = async () => {
                if (pScanner.current) {
                    (await pScanner.current).destroyContext();
                    // console message index = 12
                }
            };
            cleanup();
        };
    }, []);

    // console message index = 13

    const shouldComponentUpdate = () => false;

    return (
        <div style={{ height: "100vh", marginTop: "-3rem" }}>
            <div
                className='text-warning text-center position-absolute'
                style={{
                    width: "100vw",
                    zIndex: 100,
                    top: "5vh",
                }}
            >
                <h3>{t("videoDecode")}</h3>
            </div>
            <div ref={elRef} className='component-barcode-scanner' id='component-barcode-scanner'>
                <svg className='dce-bg-loading' viewBox='0 0 1792 1792'>
                    {/* SVG path data */}
                </svg>
                <svg className='dce-bg-camera' viewBox='0 0 2048 1792'>
                    {/* SVG path data */}
                </svg>
                <div className='dce-video-container'></div>
                <div className='dce-scanarea'>
                    <div className='dce-scanlight'></div>
                    <img
                        className='position-absolute'
                        src={barcodeOverlay}
                        alt='barcode overlay'
                        style={{
                            opacity: "0.2",
                            height: "100px",
                            width: "90%",
                            zIndex: 100,
                            left: "5%",
                            top: "calc(50% - 50px)",
                        }}
                    />
                </div>
                {/* Additional JSX elements */}
            </div>
        </div>
    );
};

export default VideoDecode;
