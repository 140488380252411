import React from "react";

import IntellicheckLogo from "./assets/IntellicheckLogo.svg";
import UHaulLogo from "./assets/UHaul/UHaulLogo.svg";
import YahooEULogo from "./assets/YahooEU/YahooEULogo.png";
import TruistLogo from "./assets/Truist/TruistLogo.svg";
import FirstAmericanLogo from "./assets/FirstAmericanTitle/FirstAmericanTitleLogo.png";
import SoFiLogo from "./assets/SoFi/SoFiLogo.png";
import GreenskyLogo from "./assets/Greensky/GreenskyLogo.svg";
import KeyBankLogo from "./assets/KeyBank/KeyBankLogo.svg";
import HIDGlobalLogo from "./assets/HIDGlobal/HIDGlobalLogo.png";
import DomaLogo from "./assets/Doma/DomaLogo.svg";
import FlexitiLogo from "./assets/Flexiti/FlexitiLogo.svg";
import UBSBankLogo from "./assets/UBSBank/UBSLogo.svg";
import DCBankLogo from "./assets/DCBank/DCBankLogo.png";
import PNCBankLogo from "./assets/PNCBank/PNCBankLogo.png";
import EarlyWarningLogo from "./assets/EarlyWarning/EarlyWarningLogo.svg";
import AmericanFirstFinanceLogo from "./assets/AmericanFirstFinance/AmericanFirstFinanceLogo.png";
import TransUnionLogo from "./assets/TransUnion/TransUnionLogo.svg";
import CapitalOneLogo from "./assets/CapitalOne/CapitalOneLogo.svg";
import BellMobilityLogo from "./assets/BellMobility/BellMobilityLogo.png";
import CitiBankLogo from "./assets/CitiBank/CitiBankLogo.svg";
import BestBuyLogo from "./assets/BestBuy/BestBuyLogo.png";
import ProgressiveLeasingLogo from "./assets/ProgressiveLeasing/ProgressiveLeasingLogo.png";
import SevenHundredCreditLogo from "./assets/SevenHundredCredit/SevenHundredCreditLogo.png";
import AARPLogo from "./assets/AARP/AARPLogo.png";
import MetaLogo from "./assets/Meta/MetaLogo.svg";
import BoeingEmployeeCreditUnionLogo from "./assets/BoeingEmployeeCreditUnion/BoeingEmployeeCreditUnionLogo.png";
import NavyFederalCreditUnionLogo from "./assets/NavyFederalCreditUnion/NavyFederalCreditUnionLogo.png";
import CommissionBeforeClosingLogo from "./assets/CommissionBeforeClosing/CommissionBeforeClosingLogo.png";
import AmericaFirstCreditUnionLogo from "./assets/AmericaFirstCreditUnion/AmericaFirstCreditUnionLogo.png";
import CentralBankLogo from "./assets/CentralBank/CentralBankLogo.jpeg";
import AccioDataLogo from "./assets/AccioData/AccioDataLogo.png";

import UHaulLandingImage from "./assets/UHaul/UHaulLandingImage.png";
import KeyBankLandingImage from "./assets/KeyBank/KeyBankLandingImage.png";
import CapitalOneLandingImage from "./assets/CapitalOne/CapitalOneLandingImage.svg";
import NavyFederalCreditUnionLandingImage from "./assets/NavyFederalCreditUnion/NavyFederalCreditUnionLandingImage.svg";

import GreenskyLanding from "./pages/custom/Greensky/GreenskyLanding";
import YahooEULandingPage from "./pages/custom/Yahoo/YahooEULandingPage";
import YahooEUCompletePage from "./pages/custom/Yahoo/YahooEUCompletePage";
import TruistLandingPage from "./pages/custom/Truist/TruistLandingPage";
import TruistCompletePage from "./pages/custom/Truist/TruistCompletePage";
import FirstAmericanTitleLanding from "./pages/custom/FirstAmericanTitle/FirstAmericanTitleLanding";
import FirstAmericanTitleComplete from "./pages/custom/FirstAmericanTitle/FirstAmericanTitleComplete";
import YahooEUPassportCapture from "./pages/custom/Yahoo/YahooEUPassportCapture";
import DomaLanding from "./pages/custom/Doma/DomaLanding";
import DomaComplete from "./pages/custom/Doma/DomaComplete";
import FlexitiLanding from "./pages/custom/Flexiti/FlexitiLanding";
import EarlyWarningLandingPage from "./pages/custom/EarlyWarning/EarlyWarningLandingPage";
import AmericanFirstFinanceLandingPage from "./pages/custom/AmericanFirstFinance/AmericanFirstFinanceLandingPage";
import TransUnionLandingPage from "./pages/custom/TransUnion/TransUnionLandingPage";
import GenericCompanyLandingPage from "./pages/custom/GenericCompanyLandingPage";
import CommissionBeforeClosingLandingPage from "./pages/custom/CommissionBeforeClosing/CommissionBeforeClosingLandingPage";

import AcmeLogo from "./assets/Acme/AcmeLogo.svg";
import AcmeLandingPage from "./pages/custom/Acme/AcmeLandingPage";
import AcmeCompletePage from "./pages/custom/Acme/AcmeCompletePage";
import AcmeResultsPage from "./pages/custom/Acme/AcmeResultsPage";

type CompanyMap = {
    [key: string]: {
        logo: string;
        css: string;
        additionalRoutes?: { route: string; element: React.ReactNode }[];
        landingImages?: string[];
        hideDefaultLanding?: boolean;
        landingComponent?: React.ReactNode;
        frontCaptureComponent?: React.ReactNode;
        barcodeCaptureComponent?: React.ReactNode;
        hideCountdownTimer?: boolean;
        completeComponent?: React.ReactNode;
    };
};

const isSimData = process.env.REACT_APP_SIMULATED;

export const companyMap: CompanyMap = {
    default: {
        logo: IntellicheckLogo,
        css: "css/intellicheck.css",
    },
    acme: {
        logo: isSimData ? IntellicheckLogo : AcmeLogo,
        css: "css/intellicheck.css",
        additionalRoutes: [{ route: "/results", element: <AcmeResultsPage /> }],
        landingComponent: <AcmeLandingPage />,
        hideDefaultLanding: true,
        hideCountdownTimer: true,
        completeComponent: <AcmeCompletePage />,
    },
    sofi: {
        logo: SoFiLogo,
        css: "css/sofi.css",
        landingComponent: <GenericCompanyLandingPage companyName='Sofi' />,
        hideCountdownTimer: true,
    },
    greensky: {
        logo: GreenskyLogo,
        css: "css/greensky.css",
        landingComponent: <GreenskyLanding />,
        hideDefaultLanding: true,
    },
    uhaul: {
        logo: UHaulLogo,
        css: "css/uhaul.css",
        landingImages: [UHaulLandingImage],
    },
    yahooeu: {
        logo: YahooEULogo,
        css: "css/yahoo.css",
        landingComponent: <YahooEULandingPage />,
        frontCaptureComponent: <YahooEUPassportCapture />,
        hideDefaultLanding: true,
        completeComponent: <YahooEUCompletePage />,
        hideCountdownTimer: true,
    },
    truist: {
        logo: TruistLogo,
        css: "css/truist.css",
        landingComponent: <TruistLandingPage />,
        hideDefaultLanding: true,
        hideCountdownTimer: true,
        completeComponent: <TruistCompletePage />,
    },
    firstamericantitle: {
        logo: FirstAmericanLogo,
        css: "css/firstamericantitle.css",
        landingComponent: <FirstAmericanTitleLanding />,
        completeComponent: <FirstAmericanTitleComplete />,
        hideCountdownTimer: true,
    },
    keybank: {
        logo: KeyBankLogo,
        css: "css/keybank.css",
        landingImages: [KeyBankLandingImage],
    },
    hidglobal: {
        logo: HIDGlobalLogo,
        css: "css/hidglobal.css",
        landingComponent: <GenericCompanyLandingPage companyName='HID Global' />,
        hideCountdownTimer: true,
    },
    doma: {
        logo: DomaLogo,
        css: "css/doma.css",
        landingComponent: <DomaLanding />,
        completeComponent: <DomaComplete />,
        hideCountdownTimer: true,
    },
    flexiti: {
        logo: FlexitiLogo,
        css: "css/flexiti.css",
        landingComponent: <FlexitiLanding />,
        hideCountdownTimer: true,
    },
    ubsbank: {
        logo: UBSBankLogo,
        css: "css/ubsbank.css",
        landingComponent: <GenericCompanyLandingPage companyName='UBS Bank' />,
        hideCountdownTimer: true,
    },
    dcbank: {
        logo: DCBankLogo,
        css: "css/dcbank.css",
        landingComponent: <GenericCompanyLandingPage companyName='DC Bank' />,
        hideCountdownTimer: true,
    },
    pncbank: {
        logo: PNCBankLogo,
        css: "css/pncbank.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='PNC Bank' />,
    },
    earlywarning: {
        logo: EarlyWarningLogo,
        css: "css/earlywarning.css",
        hideCountdownTimer: true,
        landingComponent: <EarlyWarningLandingPage />,
    },
    americanfirstfinance: {
        logo: AmericanFirstFinanceLogo,
        css: "css/americanfirstfinance.css",
        hideCountdownTimer: true,
        landingComponent: <AmericanFirstFinanceLandingPage />,
    },
    transunion: {
        logo: TransUnionLogo,
        css: "css/transunion.css",
        hideCountdownTimer: true,
        landingComponent: <TransUnionLandingPage />,
    },
    capitalone: {
        logo: CapitalOneLogo,
        css: "css/capitalone.css",
        hideCountdownTimer: true,
        landingImages: [CapitalOneLandingImage],
    },
    bellmobility: {
        logo: BellMobilityLogo,
        css: "css/bellmobility.css",
        landingComponent: <GenericCompanyLandingPage companyName='Bell Mobility' />,
        hideCountdownTimer: true,
    },
    citibank: {
        logo: CitiBankLogo,
        css: "css/citibank.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Citibank' />,
    },
    bestbuy: {
        logo: BestBuyLogo,
        css: "css/bestbuy.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Best Buy' />,
    },
    progressiveleasing: {
        logo: ProgressiveLeasingLogo,
        css: "css/progressiveleasing.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Progressive Leasing' />,
    },
    sevenhundredcredit: {
        logo: SevenHundredCreditLogo,
        css: "css/sevenhundredcredit.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='700Credit' />,
    },
    aarp: {
        logo: AARPLogo,
        css: "css/aarp.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='AARP' />,
    },
    meta: {
        logo: MetaLogo,
        css: "css/meta.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Meta' />,
    },
    boeingemployeecreditunion: {
        logo: BoeingEmployeeCreditUnionLogo,
        css: "css/boeingemployeecreditunion.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Boeing Employee Credit Union' />,
    },
    navyfederalcreditunion: {
        logo: NavyFederalCreditUnionLogo,
        css: "css/navyfederalcreditunion.css",
        hideCountdownTimer: true,
        landingImages: [NavyFederalCreditUnionLandingImage],
    },
    commissionbeforeclosing: {
        logo: CommissionBeforeClosingLogo,
        css: "css/commissionbeforeclosing.css",
        hideCountdownTimer: true,
        landingComponent: <CommissionBeforeClosingLandingPage />,
    },
    americafirstcreditunion: {
        logo: AmericaFirstCreditUnionLogo,
        css: "css/americafirstcreditunion.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='America First Credit Union' />,
    },
    centralbank: {
        logo: CentralBankLogo,
        css: "css/centralbank.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Central Bank' />,
    },
    acciodata: {
        logo: AccioDataLogo,
        css: "css/acciodata.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Accio Data' />,
    },
};
