import { useEffect } from "react";
import VideoNormalizerUI from "../components/VideoNormalizer/IDCapture/VideoNormalizerUI";
import useLogEvent from "../hooks/useLogEvent";
import { getCompanyConfig } from "../utils/getCompanyConfig";
import ErrorBoundaryWrapper from "../components/ErrorBoundary/ErrorBoundaryWrapper";
import CustJourneyCodes from "../assets/CustomerJourneyCodes.json";

const FrontPage = () => {
    const { logEvent } = useLogEvent();
    const frontCaptureComponent = getCompanyConfig("frontCaptureComponent");

    // Fire status message when user navigates to this page
    useEffect(() => {
        // logEvent("User navigated to front capture screen");
        logEvent(CustJourneyCodes.captureFront.navToPage.status);
    }, []);

    return (
        <ErrorBoundaryWrapper>
            {frontCaptureComponent ? frontCaptureComponent : <VideoNormalizerUI currScanSide='Front' docType='NA_ID' />}
        </ErrorBoundaryWrapper>
    );
};
export default FrontPage;
