import { useEffect } from "react";
import useLogEvent from "../../../hooks/useLogEvent";
import VideoNormalizerUI from "../../../components/VideoNormalizer/PassportCapture/VideoNormalizerUI";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";

const YahooEUPassportCapture = () => {
    const { logEvent } = useLogEvent();
    const routerVersion = useSelector((state: RootState) => state.app.routerVersion);

    // Fire status message when user navigates to this page
    useEffect(() => {
        // logEvent("User navigated to front capture screen");
        logEvent(CustJourneyCodes.captureFront.navToPage.status);
    }, []);

    return <VideoNormalizerUI currScanSide='Front' docType='Passport' />;
    // return <VideoRecognizer />
};
export default YahooEUPassportCapture;
