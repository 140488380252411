import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// baseUrl for the server is set in the .env file
const apiVersion = process.env.REACT_APP_API_VERSION;
const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;

/** Conditional logic on the url in each mutation/query is not as clean as conditional logic on the baseUrl alone, however, it will allow us to move away from the technical verbiage debt of using the word 'ingest'. */
// https://localhost:8000
// https://localhost:8000/api/v1/submit/ipqs-trackerid

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const state: any = getState();
            const token = state?.user?.token;
            const routerVersion = state?.user?.routerVersion;
            if (token) {
                headers.set("ingestToken", token);
                headers.set("transaction-id", token);
            }
            headers.set("routerVersion", routerVersion);
            return headers;
        },
    }),
    endpoints: (build) => ({
        postStatusUpdate: build.mutation({
            query: (params) => {
                const url = "/yahooeu/update-status";
                return {
                    url,
                    method: "POST",
                    body: {
                        statusModuleName: params.statusModuleName,
                        statusMessage: params.statusMessage,
                        statusError: params.statusError,
                        statusCode: params.statusCode,
                    },
                };
            },
        }),
        postStatusUpdateAzure: build.mutation({
            query: (params) => {
                const url = "/status/update-status";
                return {
                    url,
                    method: "POST",
                    body: {
                        message: params.message,
                        module: params.module,
                        status: params.status,
                        error: params.error,
                    },
                };
            },
        }),
        sendLogEvent: build.mutation({
            query: (params) => {
                const url = "/api/v1/log/event";
                return {
                    url,
                    method: "POST",
                    body: {
                        url: params.url,
                        event: params.event,
                        userAgent: params.userAgent,
                        sequenceNumber: params.sequenceNumber,
                    },
                };
            },
        }),
        ingestIpqsTrackerId: build.mutation({
            query: (params) => {
                return {
                    url: apiVersion === "1" ? "/api/v1/submit/ipqs-trackerid" : "/ingest/ipqs-trackerId",
                    method: "POST",
                    body: {
                        ipqsTrackerId: params.ipqsTrackerId,
                    },
                };
            },
        }),
        fetchUserConfig: build.mutation({
            query: (params) => {
                return {
                    url: apiVersion === "1" ? "/api/v1/user/get-config" : "/user/get-config",
                    method: "POST",
                    body: {
                        token: params.token,
                    },
                };
            },
        }),
        ingestFrontImage: build.mutation({
            query: (params) => {
                return {
                    url: apiVersion === "1" ? "/api/v1/submit/front" : "/ingest/front-image",
                    method: "POST",
                    body: {
                        frontImage: params.frontImage,
                    },
                };
            },
        }),
        ingestBackImage: build.mutation({
            query: (params) => {
                return {
                    url: apiVersion === "1" ? "/api/v1/submit/back" : "/ingest/back-image",
                    method: "POST",
                    body: {
                        backImage: params.backImage,
                    },
                };
            },
        }),
        ingestBarcodeData: build.mutation({
            query: (params) => {
                const url = "/ingest/barcode-data";
                return {
                    url: apiVersion === "1" ? "/api/v1/submit/barcode" : "/ingest/barcode-data",
                    method: "POST",
                    body: {
                        barcodeData: params.barcodeData,
                    },
                };
            },
        }),
        fetchAuthIdUrl: build.mutation({
            query: (params) => {
                const url = "/service/get-authid-url";
                return {
                    url: apiVersion === "1" ? "/api/v1/selfie/start" : "/service/get-authid-url",
                    method: "POST",
                    body: {},
                };
            },
        }),
        fetchAndPostAuthIdResults: build.mutation({
            query: (params) => {
                const url = "/service/fetch-and-post-authid-results";
                return {
                    url: apiVersion === "1" ? "/api/v1/selfie/end" : "/fetch-and-post-authid-results",
                    method: "POST",
                    body: {},
                };
            },
        }),
    }),
});

export const {
    useSendLogEventMutation,
    useFetchAuthIdUrlMutation,
    useFetchUserConfigMutation,
    useIngestBackImageMutation,
    useIngestFrontImageMutation,
    usePostStatusUpdateMutation,
    useIngestBarcodeDataMutation,
    useIngestIpqsTrackerIdMutation,
    usePostStatusUpdateAzureMutation,
    useFetchAndPostAuthIdResultsMutation,
} = api;
