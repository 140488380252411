import { ReactNode } from "react";
import ErrorBoundary from "./ErrorBoundary";
import useLogEvent from "../../hooks/useLogEvent";
import ErrorPage from "../../pages/ErrorPages/ErrorPage";

type ErrorBoundaryWrapperProps = {
    children: ReactNode;
};

const ErrorBoundaryWrapper = ({ children }: ErrorBoundaryWrapperProps) => {
    const { logEvent } = useLogEvent();

    return (
        <ErrorBoundary fallback={<ErrorPage />} logEvent={logEvent}>
            {children}
        </ErrorBoundary>
    );
};

export default ErrorBoundaryWrapper;
