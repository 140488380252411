import { Routes, Route, useLocation } from "react-router-dom";
import MainOutlet from "../MainOutlet";
import { AnimatePresence } from "framer-motion";
import SlideTransition from "../components/SlideTransition/SlideTransition";
import FrontPage from "../pages/FrontPage";
import BackPage from "../pages/BackPage";
import SelfieCapture from "../components/SelfieCapture/AuthIdComponent";
import ErrorPage from "../pages/ErrorPages/ErrorPage";
import TimeoutPage from "../pages/ErrorPages/TimeoutPage";
import FourZeroFourPage from "../pages/ErrorPages/FourZeroFourPage";
import BiometricsDeniedPage from "../pages/ErrorPages/BiometricsDeniedPage";
import CameraPermissionsDenied from "../pages/ErrorPages/CameraPermissionsDeniedPage";
import BarcodePage from "../pages/BarcodePage";
import AcmeLandingPage from "../pages/custom/Acme/AcmeLandingPage";
import AcmeCompletePage from "../pages/custom/Acme/AcmeCompletePage";
import AcmeResultsPage from "../pages/custom/Acme/AcmeResultsPage";

const AcmeRouter = (): JSX.Element => {
    const currLocation = useLocation();
    return (
        <>
            <AnimatePresence mode='wait'>
                <Routes location={currLocation} key={currLocation.pathname}>
                    <Route element={<MainOutlet />}>
                        <Route
                            path='/'
                            element={
                                <SlideTransition>
                                    <AcmeLandingPage />
                                </SlideTransition>
                            }
                        />

                        <Route
                            path='/front'
                            element={
                                <SlideTransition>
                                    <FrontPage />
                                </SlideTransition>
                            }
                        />

                        <Route
                            path='/back'
                            element={
                                <SlideTransition>
                                    <BackPage />
                                </SlideTransition>
                            }
                        />

                        <Route
                            path='/barcode'
                            element={
                                <SlideTransition>
                                    <BarcodePage />
                                </SlideTransition>
                            }
                        />

                        <Route path='/selfie' element={<SelfieCapture />} />

                        <Route
                            path='/results'
                            element={
                                <SlideTransition>
                                    <AcmeResultsPage />
                                </SlideTransition>
                            }
                        />

                        <Route
                            path='/complete'
                            element={
                                <SlideTransition>
                                    <AcmeCompletePage />
                                </SlideTransition>
                            }
                        />
                        <Route
                            path='/error'
                            element={
                                <SlideTransition>
                                    <ErrorPage />
                                </SlideTransition>
                            }
                        />
                        <Route
                            path='/token-expired'
                            element={
                                <SlideTransition>
                                    <TimeoutPage />
                                </SlideTransition>
                            }
                        />
                        <Route
                            path='/biometrics-denied'
                            element={
                                <SlideTransition>
                                    <BiometricsDeniedPage />
                                </SlideTransition>
                            }
                        />
                        <Route
                            path='/camera-denied'
                            element={
                                <SlideTransition>
                                    <CameraPermissionsDenied />
                                </SlideTransition>
                            }
                        />
                        <Route
                            path='/*'
                            element={
                                <SlideTransition>
                                    <FourZeroFourPage />
                                </SlideTransition>
                            }
                        />
                    </Route>
                </Routes>
            </AnimatePresence>
        </>
    );
};

export default AcmeRouter;
