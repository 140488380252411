import React from "react";
import { useTranslation, Trans } from "react-i18next";

const EarlyWarningLandingPage = () => {
    const { t } = useTranslation();

    return (
        <div className='d-flex justify-content-center align-items-center flex-column text-center position-relative'>
            <h1 className='mt-4'>
                <Trans i18nKey='earlywarning.landingText1'>
                    When money moves, life happens<sup className='fs-4'>{t("earlywarning.landingText1")}</sup>.
                </Trans>
            </h1>
            <p className='mt-5 mb-5'>{t("genericLandingPageText.text2")}</p>
            <p className='mt-5 mb-5'>{t("genericLandingPageText.text3")}</p>
        </div>
    );
};

export default EarlyWarningLandingPage;
