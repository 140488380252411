import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type GenericCompanyLandingPageProps = {
    companyName: string;
};

function GenericCompanyLandingPage({ companyName }: GenericCompanyLandingPageProps) {
    const { t } = useTranslation();

    return (
        <Stack gap={1} className='justify-content-evenly text-center position-relative'>
            <h1 className='mb-4'>
                {t("genericLandingPageText.text1")}
                {companyName}.
            </h1>
            <p className='mt-4 mb-4'>{t("genericLandingPageText.text2")}</p>
            <p className='mt-4 mb-4'>{t("genericLandingPageText.text3")}</p>
        </Stack>
    );
}

export default GenericCompanyLandingPage;
