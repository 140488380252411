/**
 * This is the main application component that serves as the entry point for the entire application.
 *
 * It handles routing using React Router, loading WebAssembly (Wasm) resources, and rendering different
 * components based on the current route. The component also displays a loading message while Wasm is loading.
 *
 * @component
 * @example
 * // Import the App component in your application
 * import App from './App';
 *
 * // Render the App component in your application
 * ReactDOM.render(<App />, document.getElementById('root'));
 */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "react-bootstrap";
import QRCode from "react-qr-code";
import { useFetchAuthIdUrlMutation, useFetchUserConfigMutation } from "./api/api";
import { RootState } from "./redux/store";
import { setUserObject } from "./redux/slices/user.slice";
import DefaultRouter from "./routers/DefaultRouter";
import IpqsTracker from "./components/IpqsTracker/IpqsTracker";
import AcmeRouter from "./routers/AcmeRouter";

const AcmeApp = () => {
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const dispatch = useDispatch();
    const [fetchUserConfig, userConfig] = useFetchUserConfigMutation();
    const [fetchAuthIdUrl, authId] = useFetchAuthIdUrlMutation();
    const { authIdUrl: userAuthIdUrl, captureRequirements } = useSelector((state: RootState) => state.user);

    const token = urlParams.get("token");
    const language = urlParams.get("language");

    const defaultEnvVersion = process.env.REACT_APP_DEFAULT_EXPERIENCE;

    // set the token and routerVersion on state
    React.useEffect(() => {
        let obj = {};
        obj = { ...obj, ...{ routerVersion: defaultEnvVersion } };
        if (language) {
            obj = { ...obj, ...{ language } };
        }
        if (token) {
            obj = { ...obj, ...{ token: token } };
        } else {
            const now = Date.now();
            obj = {
                ...obj,
                ...{
                    token: `acme_${now}`, // add a fake token so the authid url comes back correctly
                },
            };
        }
        dispatch(setUserObject(obj));
    }, [token, language]);

    // Process the userConfig
    React.useEffect(() => {
        if (!userAuthIdUrl) {
            fetchAuthIdUrl({});
        }

        if (token) {
            fetchUserConfig({ token: token });
        }

        let obj = {
            captureRequirements,
        };
        dispatch(setUserObject(obj));
    }, [captureRequirements]);

    React.useEffect(() => {
        if (!authId.isUninitialized && !authId.isLoading && !authId.isError && authId.isSuccess) {
            dispatch(setUserObject({ authIdUrl: authId?.data?.payload?.authIdUrl }));
        }
    }, [authId]);

    const handleOrientationChange = () => {
        const currentOrientationAngle = window?.screen?.orientation?.angle;
        if (currentOrientationAngle) {
            const isLandscape = Math.abs(currentOrientationAngle) === 90 || Math.abs(currentOrientationAngle) === 270;
            if (isLandscape) {
                // Alert user that landscape is not available
                alert("Rotate device. Portrait mode only.");
            }
        }
    };

    React.useEffect(() => {
        // Add event listener for orientation change
        window.addEventListener("orientationchange", handleOrientationChange);

        // Check the initial orientation
        handleOrientationChange();

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);

    return (
        <div className='App'>
            <div className='d-block d-lg-none'>
                <AcmeRouter />
                {token && !userConfig.isError && !userConfig.isUninitialized && !userConfig.isLoading && <IpqsTracker />}
            </div>
            <div className='d-none d-lg-block'>
                <Stack direction='vertical' gap={3} className='align-items-center justify-content-center vh-100'>
                    <div>Point your phone camera at this QR code to start the ID verification process</div>
                    <div>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: 250, width: "100%" }}
                            value={window.location.href}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                </Stack>
            </div>
        </div>
    );
};

export default AcmeApp;
