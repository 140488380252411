import React from "react";
import Logo from "../Logo/Logo";
import background from "../../assets/background.jpg";

type OwnProps = {
    children: React.ReactNode;
};

const BackgroundAndLogo: React.FC<OwnProps> = ({ children }) => {
    return (
        <div
            className='container-fluid w-100 vh-100 d-flex flex-column align-items-center'
            style={{
                backgroundImage: `url(${background})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
        >
            <Logo />
            {children}
        </div>
    );
};

export default BackgroundAndLogo;
